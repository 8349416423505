import React from 'react'
import './scene.css';
import { ArcRotateCamera } from '@babylonjs/core/Cameras/arcRotateCamera'
import { Color3,  Vector3 } from '@babylonjs/core/Maths/math'
import { SceneLoader } from "@babylonjs/core/Loading/sceneLoader";
import { Button } from '@babylonjs/gui/2D/controls/button'
import BabylonScene from 'babylonjs-hook'
import { navigate} from 'gatsby'
import { AdvancedDynamicTexture } from '@babylonjs/gui/2D/advancedDynamicTexture'
import { TextBlock } from '@babylonjs/gui/2D/controls/textBlock'
import {Control} from '@babylonjs/gui/2D/controls/control'
import {Rectangle } from '@babylonjs/gui/2D/controls/rectangle'
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial"
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import { Texture } from "@babylonjs/core/Materials/Textures/texture"
import { DirectionalLight } from '@babylonjs/core/Lights/directionalLight'
import { GlowLayer } from '@babylonjs/core'



let box;

const onSceneReady = async (scene) => {  
    const canvas = scene.getEngine().getRenderingCanvas();  
    scene.autoClearDepthAndStencil = false; // Depth and stencil, obviously
    scene.blockMaterialDirtyMechanism  = true
    SceneLoader.ShowLoadingScreen = false;
    scene.clearColor = new  Color3(0.29, 0.29, 0.97);

var camera = new ArcRotateCamera("Camera", 3 * Math.PI / 4, Math.PI / 2, 4, Vector3.Zero(), scene);
camera.attachControl(canvas, true);
camera.position.y = -30

var light1 = new DirectionalLight("DirectionalLight", new Vector3(5, 20, 0), scene);
light1.intensity = 0.4;

const gl = new GlowLayer("glow, scene")
    
box = MeshBuilder.CreateBox("box", scene);   
box.position.y = 10

const boxMesh = scene.getMeshByName("box")
gl.addExcludedMesh(boxMesh)




let adt = AdvancedDynamicTexture.CreateFullscreenUI("UI");

const loginRight = new Rectangle();
    loginRight.width = "200px";
    loginRight.height = "200px";
    loginRight.thickness = 0;
    loginRight.verticalAlignment =  Control.VERTICAL_ALIGNMENT_CENTER
    loginRight.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;  
    adt.addControl(loginRight);

const  buttonLogin = Button.CreateImageOnlyButton('buttonLoginBabylonjs', "https://raw.githubusercontent.com/fredpaq/stock/main/login1.png");
    buttonLogin.width = "120px";
    buttonLogin.height = "120px";
    buttonLogin.alpha = 0.6;
    buttonLogin.color = "yellow";
    buttonLogin.isHighlighted = true;
    buttonLogin.shadowBlur = 11;  
    buttonLogin.border = "none"
    loginRight.addControl(buttonLogin)
 
 buttonLogin.onPointerDownObservable.add(function() {
  // window.location.replace ( "https://www.gatsbyjs.com/")
  navigate( '/account/')
 });

 const login = new TextBlock();
 login.top = "30px"
 login.height = "20px";
 login.color = "yellow";     
 login.text = "Login";
 login.alpha = 0.8;
 
 loginRight.addControl(login)


// Ground ===========================================================
const subdivisions = 400;
const width = 400;
const height = 400;
const options = {width: width, height: height, subdivisions: subdivisions, minHeight: 0 ,  maxHeight: 10};
const ground = MeshBuilder.CreateGroundFromHeightMap("ground", "https://raw.githubusercontent.com/fredpaq/stock/main/pastry1.jpg", options, scene);
//ground.rotation.y  = -Math.PI/3 

const groundMaterial = new StandardMaterial("ground", scene);
groundMaterial.emissiveColor = new Color3.FromHexString("#FFFFCC")
groundMaterial.diffuseTexture = new Texture("https://raw.githubusercontent.com/fredpaq/stock/main/pastry1.jpg", scene);
groundMaterial.freeze();
ground.material = groundMaterial;
ground.receiveShadows = true;
ground.isVisible  = true


camera.setTarget(box.position)
}

const onRender = scene => {
    if (box !== undefined) {
      var deltaTimeInMillis = scene.getEngine().getDeltaTime();
  
      const rpm = 10;
      box.rotation.y += ((rpm / 60) * Math.PI * 2 * (deltaTimeInMillis / 1000));
    }
  }
  
export default   () => (
    <BabylonScene antialias onSceneReady={onSceneReady} onRender={onRender} id='render-canvas' />
 )